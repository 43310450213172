<template>
    <div class="cookies-policy">
      <div v-if="currentCookiesText" class="cookies-content">
        <!-- Customer specific header if available -->
        <div v-if="currentCookiesText.headerImage" class="customer-header">
          <img 
            :src="currentCookiesText.headerImage"
            :alt="currentCookiesText.customer + ' Header'"
            class="w-100"
          />
        </div>
        
        <!-- cookies content -->
        <div class="cookies-container">
          <div class="cookies-header">
            <h1 class="title">
                {{ currentTranslation.title }}
            </h1>
            <p class="last-update">
              Última actualización: {{ cookiesLastUpdate }}
            </p>
          </div>
          
          <!-- Render HTML content safely -->
          <div v-html="currentTranslation.content"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { cookiesTexts } from './CookiesTextsVersions'
  
  export default {
    name: 'CookiesPolicy',
    data() {
      return {
        currentCookiesText: null
      }
    },
    computed: {
      currentLocale() {
        return this.$route?.query?.locale || this.$store.state.locale.currentLocale || 'es'
      },
      currentTranslation() {
        if (!this.currentCookiesText) return null
        const translations = this.currentCookiesText.translations || cookiesTexts.default.translations
        return translations[this.currentLocale] || translations.es // Fallback to Spanish
      },
      cookiesLastUpdate(){
        return this.currentCookiesText.lastUpdate || cookiesTexts.default.lastUpdate;
      }
    },
    created() {
      // Get customer from route params or use default
      const customer = this.$route.params.communityId?.toLowerCase() || 'default'
      this.currentCookiesText = cookiesTexts[customer] || cookiesTexts.default
      if(!this.currentCookiesText.translations){
        this.currentCookiesText.translations = cookiesTexts.default.translations
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .cookies-policy {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  
    .customer-header {
      margin-bottom: 2rem;
      
      img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }
  
    .cookies-container {
      background: white;
      padding: 2rem;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  
      .cookies-header {
        margin-bottom: 2rem;
  
        .title {
          font-size: 1.75rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
  
        .last-update {
          color: #666;
          font-size: 0.9rem;
        }
      }
    }
  }
  </style>
  